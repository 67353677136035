import React, { useEffect, useState } from 'react';
import './bca.scss';

const Bca = () => {

  useEffect(() => {
    fetch('./static/media/data.json', { method: 'GET' })
      .then((res) => res.json())
      .then((res) => setPics(res.bca));
  });
  const [pics, setPics] = useState([]);
  const [currentIndex, setIndex] = useState(null);
  return (
    <div className="pics-container">
      <div className="pics">
        {
          pics.map((pic, i) => (
            <div className="all-pics" onClick={() => setIndex(i)}>
              <img src={`http://rendeles.mandragorabudapest.hu/static/media/${pic}`} alt="" />
            </div>
          ))
        }
        <div className={`modal${currentIndex !== null ? ' active' : ''}`} onClick={() => setIndex(null)}>
          <div className="container" onClick={(e) => e.stopPropagation()}>
            <div className="arrow" onClick={() => currentIndex !== 0 ? setIndex(currentIndex - 1) : setIndex(pics.length - 1)}><p>&lt;</p></div>
            <img src={`http://rendeles.mandragorabudapest.hu/static/media/${pics[currentIndex || 0]}`} alt="" />
            <div className="arrow" onClick={() => currentIndex !== pics.length - 1 ? setIndex(currentIndex + 1) : setIndex(0)}><p>&gt;</p></div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Bca;
