import React, { useEffect, useState } from 'react';
import IItem from './types/IItem';

const Admin = () => {
  const [mainData, setData] = useState([]);
  const [menuData, setMenuData] = useState([]);
  const [streets, setStreets] = useState([]);
  const [bca, setBca] = useState([]);
  const [menuStreets, setMenuStreets] = useState([]);
  const [json, printJson] = useState(false);
  useEffect(() => {
    fetch('../static/media/data.json', { method: 'GET' })
      .then((res) => res.json())
      .then((res) => {
        setData(Object.entries(res.home).map(([key, value], index) => ({
          title: key,
          data: value,
          index,
        }), {}));
        setMenuData(Object.entries(res.menu).map(([key, value], index) => ({
          title: key,
          data: value,
          index,
        }), {}));
        setStreets(res.streets);
        setMenuStreets(res.menuStreets);
        setBca(res.bca);
      });
  }, []);

  const getJson = () => {
    const home = mainData.sort((a, b) => a.index - b.index).reduce((prev, next) => ({ ...prev, [next.title]: next.data }), {})
    const menu = menuData.sort((a, b) => a.index - b.index).reduce((prev, next) => ({ ...prev, [next.title]: next.data }), {})
    return {
      home,
      menu,
      streets,
      menuStreets,
      bca,
    }
  }

  return (
    <div style={{ overflowY: 'scroll', height: '100vh' }}>
      <div onClick={() => printJson(!json)}>KÉSZ</div>
      { !json
        ? (
          <div>
            <div>------------------------------------ FŐOLDAL -----------------------------------------------</div>
            <div style={{ margin: '20px 0' }} onClick={() => setData([...mainData, { index: mainData.length, title: 'nevtelen', data: [] }])}>Új típus</div>
            {
              mainData.sort((a, b) => a.index - b.index).map(({ index, title, data }) => (
                <div>
                  <input value={title} onChange={(e) => setData([...mainData.map((type) => type.index === index ? ({ ...type, title: e.target.value }) : type)])} type="text" style={{ margin: '10px 0', fontWeight: 700 }} />
                  <span onClick={() => setData([...mainData.filter((type) => type.index !== index)])} style={{ color: 'red' }}>Törlés</span>
                  <span style={{ color: 'green', margin: '10px' }} onClick={() => setData([...mainData.map((type: any) => type.index === index ? ({ ...type, data: [...type.data, { name: '', price: 0, description: '', img: '' }] }) : type)])}>Új tétel</span>
                  { index !== 0 && (<span style={{ color: 'brown', margin: '0 10px' }} onClick={() => setData([...mainData.map((type) => type.index === index || type.index === index - 1 ? { ...type, index: type.index === index ? index - 1 : index } : type)])}>FEL</span>)}
                  { index !== mainData.length - 1 && (<span style={{ color: 'orange', margin: '0 10px' }} onClick={() => setData([...mainData.map((type) => type.index === index || type.index === index + 1 ? { ...type, index: type.index === index ? index + 1 : index } : type)])}>LE</span>)}
                  <div>
                    {data.map((item: IItem<{}>, iIndex: number) => (
                      <div>
                        <label htmlFor="">
                          név
                          <input onChange={(e) => setData([...mainData.map((type) => type.index === index ? ({ ...type, data: [...type.data.map((item: IItem<{}>, itemIndex: number) => iIndex === itemIndex ? ({ ...item, name: e.target.value }) : item)] }) : type)])} type="text" value={item.name} />
                        </label>
                        <label htmlFor="">
                          ár
                          <input onChange={(e) => setData([...mainData.map((type) => type.index === index ? ({ ...type, data: [...type.data.map((item: IItem<{}>, itemIndex: number) => iIndex === itemIndex ? ({ ...item, price: e.target.value }) : item)] }) : type)])} type="number" value={item.price} />
                        </label>
                        <label htmlFor="">
                          leírás
                          <input onChange={(e) => setData([...mainData.map((type) => type.index === index ? ({ ...type, data: [...type.data.map((item: IItem<{}>, itemIndex: number) => iIndex === itemIndex ? ({ ...item, description: e.target.value }) : item)] }) : type)])} type="text" value={item.description} />
                        </label>
                        <label htmlFor="">
                          képnév
                          <input onChange={(e) => setData([...mainData.map((type) => type.index === index ? ({ ...type, data: [...type.data.map((item: IItem<{}>, itemIndex: number) => iIndex === itemIndex ? ({ ...item, img: e.target.value }) : item)] }) : type)])} type="text" value={item.img} />
                        </label>
                        <span onClick={() => setData([...mainData.map((type) => type.index === index ? ({ ...type, data: [...type.data.filter((item: IItem<{}>, itemIndex: number) => itemIndex !== iIndex)] }) : type)])} style={{ color: 'red' }}>Törlés</span>
                      </div>
                    ))}
                  </div>
                </div>
              ))
            }
            <div>------------------------------------ MENU -----------------------------------------------</div>
            <div style={{ margin: '20px 0' }} onClick={() => setMenuData([...menuData, { index: menuData.length, title: 'nevtelen', data: [] }])}>Új típus</div>
            {
              menuData.sort((a, b) => a.index - b.index).map(({ index, title, data }) => (
                <div>
                  <input value={title} onChange={(e) => setMenuData([...menuData.map((type) => type.index === index ? ({ ...type, title: e.target.value }) : type)])} type="text" style={{ margin: '10px 0', fontWeight: 700 }} />
                  <span onClick={() => setMenuData([...menuData.filter((type) => type.index !== index)])} style={{ color: 'red' }}>Törlés</span>
                  <span style={{ color: 'green', margin: '10px' }} onClick={() => setMenuData([...menuData.map((type: any) => type.index === index ? ({ ...type, data: [...type.data, { name: '', price: 0, description: '', img: '' }] }) : type)])}>Új tétel</span>
                  { index !== 0 && (<span style={{ color: 'brown', margin: '0 10px' }} onClick={() => setMenuData([...menuData.map((type) => type.index === index || type.index === index - 1 ? { ...type, index: type.index === index ? index - 1 : index } : type)])}>FEL</span>)}
                  { index !== menuData.length - 1 && (<span style={{ color: 'orange', margin: '0 10px' }} onClick={() => setMenuData([...menuData.map((type) => type.index === index || type.index === index + 1 ? { ...type, index: type.index === index ? index + 1 : index } : type)])}>LE</span>)}
                  <div>
                    {data.map((item: IItem<{}>, iIndex: number) => (
                      <div>
                        <label htmlFor="">
                          név
                          <input onChange={(e) => setMenuData([...menuData.map((type) => type.index === index ? ({ ...type, data: [...type.data.map((item: IItem<{}>, itemIndex: number) => iIndex === itemIndex ? ({ ...item, name: e.target.value }) : item)] }) : type)])} type="text" value={item.name} />
                        </label>
                        <label htmlFor="">
                          ár
                          <input onChange={(e) => setMenuData([...menuData.map((type) => type.index === index ? ({ ...type, data: [...type.data.map((item: IItem<{}>, itemIndex: number) => iIndex === itemIndex ? ({ ...item, price: e.target.value }) : item)] }) : type)])} type="number" value={item.price} />
                        </label>
                        <label htmlFor="">
                          leírás
                          <input onChange={(e) => setMenuData([...menuData.map((type) => type.index === index ? ({ ...type, data: [...type.data.map((item: IItem<{}>, itemIndex: number) => iIndex === itemIndex ? ({ ...item, description: e.target.value }) : item)] }) : type)])} type="text" value={item.description} />
                        </label>
                        <label htmlFor="">
                          képnév
                          <input onChange={(e) => setMenuData([...menuData.map((type) => type.index === index ? ({ ...type, data: [...type.data.map((item: IItem<{}>, itemIndex: number) => iIndex === itemIndex ? ({ ...item, img: e.target.value }) : item)] }) : type)])} type="text" value={item.img} />
                        </label>
                        <span onClick={() => setMenuData([...menuData.map((type) => type.index === index ? ({ ...type, data: [...type.data.filter((item: IItem<{}>, itemIndex: number) => itemIndex !== iIndex)] }) : type)])} style={{ color: 'red' }}>Törlés</span>
                      </div>
                    ))}
                  </div>
                </div>
              ))
            }
            <div>------------------------------------ BCA -----------------------------------------------</div>
            <div onClick={() => setBca([...bca, ''])} style={{ color: 'green' }}>ÚJ BCA</div>
            {
              bca.map((bcaa, i) => (
                <div>
                  <input type="text" value={bcaa} />
                  <span onClick={() => setBca(bca.filter((_, ind) => i !== ind))} style={{ color: 'red' }}>TÖRLÉS</span>
                </div>
              ))
            }
          </div>
        )
        : (
          <label htmlFor="">
            <textarea value={JSON.stringify(getJson())} id="copy" style={{ backgroundColor: 'aqua', minHeight: 100 }} onClick={(e: any) => {
              window.alert('Vágólapra másolva')
              const selBox = document.createElement('textarea');
              selBox.style.position = 'fixed';
              selBox.style.left = '0';
              selBox.style.top = '0';
              selBox.style.opacity = '0';
              selBox.value = e.target.value;
              document.body.appendChild(selBox);
              selBox.focus();
              selBox.select();
              document.execCommand('copy', true, e.target.value);
              document.body.removeChild(selBox);
            }} />
          </label>
        )

      }
    </div>
  );
};

export default Admin;
