export default class MyForm {
  firstName: string = '';
  lastName: string = '';
  email: string = '';
  phone: string = '';
  deliveryType: string = '';
  paymentMethod: string = '';
  postNumber: string = '';
  address: string = '';
  houseNumber: string = '';
  floor: string = '';
  door: string = '';
  bell: string = '';
  comment: string = '';
  deliveryDate: string = 'Most';
  deliveryTime: string = '';
  price: number = 300;
}
