import React from 'react';
import IItem from "./IItem";
import MyForm from "./MyForm";
import { renderEmail } from 'react-html-email';

export default class Email {
  static getContactEmail(form: MyForm) {
    return renderEmail(
      <div>
        <div>{`Kedves ${form.firstName} ${form.lastName}!`}</div>
        <br/>
        <div>A rendelést megkaptuk! Hamarosan visszajelzünk.</div>
        <br/>
        <div>Mandragóra étterem</div>
      </div>
    );
  }

  static getOrderEmail(form: MyForm, items: IItem<{ amount: number, index?: number, desc?: 'string' }>[], discount: number, deliveryPrice: number) {
    return renderEmail((
      <div>
        <div>Rendelés</div>
        <br/>
        <div>{`Név: ${form.firstName} ${form.lastName}`}</div>
        <div>{`Rendelés típusa: ${form.deliveryType} | ${form.paymentMethod} | ${form.deliveryDate} | ${form.deliveryTime}`}</div>
        <div>{`Email: ${form.email}`}</div>
        <div>{`Telefon: ${form.phone}`}</div>
        <div>{`Cím: ${form.postNumber} ${form.address} ${form.houseNumber}. | ${form.floor} (emelet) | ${form.door} (ajtó) | ${form.bell} (csengő)`}</div>
        <div>{`Megjegyzés: ${form.comment}`}</div>
        <div style={{ color: 'tomato', fontSize: 16 }}>{`Kedvezmény: ${discount} Ft`}</div>
        <div style={{ color: 'tomato', fontSize: 16 }}>{`Kiszállítási díj: ${deliveryPrice} Ft`}</div>
        <table>
          <thead>
            <td>Tétel</td>
            <td>db</td>
            <td>Ár</td>
            <td>Összesen</td>
            <td>Megj</td>
          </thead>
          <tbody>
            {
              items.map((item) => (
                <tr>
                  <td>{item.name}</td>
                  <td>{item.generic.amount} db.</td>
                  <td>{item.price} Ft</td>
                  <td>{item.generic.amount * item.price} Ft</td>
                  <td>{item.generic.desc}</td>
                </tr>
              ))
            }
          </tbody>
        </table>
      </div>
    ));
  }
}