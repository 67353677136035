export default class Dates {
  static current = new Date();
  private static dates = [
    { index: -1, day: 'Szombat' },
    { index: 1, day: 'Hétfő' },
    { index: 2, day: 'Kedd' },
    { index: 3, day: 'Szerda' },
    { index: 4, day: 'Csütörtök' },
    { index: 5, day: 'Péntek' },
    { index: 6, day: 'Szombat' },
  ];

  static getDays() {
    const currentDay = this.current.getDay();
    if (currentDay >= 5) {
      return this.createDays(this.dates)
    }
    return this.createDays(this.dates.filter((date) => date.index >= currentDay));
  }

  private static createDays(dates: any[]) {
    return dates.map((date) => `${new Date(this.current.getTime() + ((date.index - this.current.getDay()) * 24 * 60 * 60 * 1000)).toLocaleDateString()} - ${date.day}`);
  }

  static getIntervals(selected: string) {
    const result = [];
    if (new Date(selected.slice(0, 13)).getDay() === this.current.getDay()) {
      let currentHours = this.current.getHours();
      let currentMinutes = this.current.getMinutes();
  
      if (currentMinutes >= 30) {
        currentMinutes -= 30;
        currentHours += 1;
      } else {
        currentMinutes += 30;
      }
  
      for(let hour = currentHours; hour < 22; hour++) {
        for(let minute = 0; minute < 60; minute += 15) {

          if (currentHours === hour) {
            if (currentMinutes < minute) {
              result.push(`${`0${hour.toString()}`.slice(-2)}:${`0${minute.toString()}`.slice(-2)}`);
            }
          } else if (hour === 21) {
            if (minute === 0) {
              result.push(`${`0${hour.toString()}`.slice(-2)}:${`0${minute.toString()}`.slice(-2)}`);
            }
          } else if (hour > currentHours) {
            if (!(minute === 0 && hour === 11 || minute === 15 && hour === 11)) {
              result.push(`${`0${hour.toString()}`.slice(-2)}:${`0${minute.toString()}`.slice(-2)}`);
            } 
          }
        }
      }
      return result;
    }

    for(let hour = 11; hour < 22; hour++) {
      for(let minute = 0; minute < 60; minute += 15) {
        if (hour === 21) {
          if (minute === 0) {
            result.push(`${`0${hour.toString()}`.slice(-2)}:${`0${minute.toString()}`.slice(-2)}`);
          }
        } else if (hour === 11) {
          if (minute === 30 || minute === 45) {
            result.push(`${`0${hour.toString()}`.slice(-2)}:${`0${minute.toString()}`.slice(-2)}`);
          }
        } else {
          if (!(minute === 0 && hour === 11 || minute === 15 && hour === 11)) {
            result.push(`${`0${hour.toString()}`.slice(-2)}:${`0${minute.toString()}`.slice(-2)}`);
          }
        }
      }
    }
    return result;
  }
}
