import React from 'react';
import { Switch, Route, BrowserRouter as Router } from 'react-router-dom';
import Admin from './Admin';
import App from './App';
import Bca from './Bca';

const Root = () => (
  <Router>
    <Switch>
      <Route path="/" exact component={App} />
      <Route path="/menu" component={App} />
      <Route path="/bca" component={Bca} />
      <Route path="/admin/titkosjelszo" component={Admin} />
    </Switch>
  </Router>
);

export default Root;
