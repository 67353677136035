export const SET_DATA = 'SET_DATA';

interface SetData {
  type: typeof SET_DATA;
  payload: {
    data: any,
  };
}

export const setData = (data: any): SetData => ({
  type: SET_DATA,
  payload: { data },
});

type DataTypes = SetData;

export default DataTypes;
