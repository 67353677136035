import { Index } from 'src/App';
import IItem from 'src/types/IItem';

export const ADD_TO_CART = 'ADD_TO_CART';
export const SUBTRACT_FROM_CART = 'SUBTRACT_FROM_CART';
export const REMOVE_FROM_CART = 'REMOVE_FROM_CART';
export const EMPTY_CART = 'EMPTY_CART';

interface AddToCart {
  type: typeof ADD_TO_CART;
  payload: {
    item: IItem<{ amount: number, menu?: number, desc?: string }>,
  };
}

export const addToCart = (item: IItem<{ amount: number, menu?: Index, desc?: string, has?: Index[], optional?: Index [] }>): AddToCart => ({
  type: ADD_TO_CART,
  payload: { item },
});

interface SubtractFromCart {
  type: typeof SUBTRACT_FROM_CART;
  payload: {
    item: IItem<{ amount: number }>,
  };
}

export const subtractFromCart = (item: IItem<{ amount: number }>): SubtractFromCart => ({
  type: SUBTRACT_FROM_CART,
  payload: { item },
});

interface RemoveFromCart {
  type: typeof REMOVE_FROM_CART;
  payload: {
    item: IItem<{ amount: number }>,
  };
}

export const removeFromCart = (item: IItem<{ amount: number }>): RemoveFromCart => ({
  type: REMOVE_FROM_CART,
  payload: { item },
});

interface EmptyCart {
  type: typeof EMPTY_CART;
}

export const emptyCart = (): EmptyCart => ({
  type: EMPTY_CART,
});

export type MainActionTypes = AddToCart | SubtractFromCart | RemoveFromCart | EmptyCart;
