import DataTypes, { SET_DATA } from '../../actions/DataAction';

const dataReducer = (state: { hasLoaded: boolean, data: any, menu: any, streets: any, menuStreets: any, postal: any, menuPostal: any } = { hasLoaded: false, data: [], menu: [], streets: [], menuStreets: [], postal: [], menuPostal: [] }, action: DataTypes) => {
  switch (action.type) {
    case SET_DATA: {
      

      return {
        ...state,
        hasLoaded: true,
        data: {
          ...action.payload.data.home,
        },
        menu: {
          ...action.payload.data.menu,
        },
        streets: action.payload.data.streets.map((street: any) => action.payload.data.menuStreets.find((mStreet: any) => mStreet.street === street.street && mStreet.postal === street.postal) ? ({ ...street, price: 0 }) : ({ ...street, price: 300 })),
        postal: action.payload.data.streets.reduce((prev: any[], { postal }: { postal: string }) => !prev.some(({ value }) => value === postal.toString()) ? [...prev, { value: postal.toString() }] : prev, []),
      };
    }

    default:
      return state;
  }
}

export default dataReducer;
