import { createStore } from 'redux';
import rootReducer from '../reducers/rootReducer';

let preloadedState;
const persistedTodosString = JSON.parse(localStorage.getItem('todos'));

if (persistedTodosString) {
  preloadedState = {
    main: persistedTodosString,
  };
}

const store = createStore(rootReducer, preloadedState);

export default store;