import { combineReducers } from 'redux';
import mainReducer from './main/mainReducer';
import dataReducer from './data/dataReducer';

const rootReducer = combineReducers({
  main: mainReducer,
  data: dataReducer,
});

export default rootReducer;
