import React, { useState } from 'react';
import { connect } from 'react-redux';
import IItem from 'src/types/IItem';
import { ReactComponent as PlusSvg } from '../../assets/plus.svg';
import { ReactComponent as MinusSvg } from '../../assets/minus.svg';
import { ReactComponent as AddCartSvg } from '../../assets/addCart.svg';
import { addToCart } from '../../actions/Action';
import './item.scss';


const Item = ({ item, dpAddToCart, isMenu, index, setStep }: ItemProps) => {
  const [counter, setCounter] = useState(1);

  const add = () => {
    setCounter(counter + 1);
  }

  const remove = () => {
    if (counter !== 1) {
      setCounter(counter - 1);
    }
  }

  return (
    <div className="item">
      <div className="item-content">
        <div className="item-text">
          <div className="item-name">
            <div>{item.name}</div>
            <span />
            {+item.price !== 0 && (<div className={isMenu ? `${index === -1 ? '' : ''}` : ''}>{`${item.price} Ft`}</div>)}
          </div>
          <div className="item-description">
            {item.description}
          </div>
        </div>
        <img src={`http://rendeles.mandragorabudapest.hu/static/media/${item.img}`} alt="" onClick={() => setStep(`http://rendeles.mandragorabudapest.hu/static/media/${item.img}`)} />
        <div className="action-row">
          <div className="action-row-buttons">
            <div
              className="calc-container"
              role="button"
              tabIndex={-1}
              onClick={remove}
            >
              <MinusSvg className="calc" />
            </div>
            <div className="counter">{counter}</div>
            <div
              className="calc-container"
              role="button"
              tabIndex={-1}
              onClick={add}
            >
              <PlusSvg className="calc" />
            </div>
          </div>
          <AddCartSvg className="cart" onClick={() => dpAddToCart({ ...item, generic: { amount: counter, menu: isMenu ? index : undefined } })} />
          {+item.price !== 0 && (<div className={isMenu ? `price ${index === -1 ? '' : ''}` : 'price'}>{`${item.price} Ft`}</div>)}
        </div>
      </div>
      <img src={`http://rendeles.mandragorabudapest.hu/static/media/${item.img}`} alt="" onClick={() => setStep(`http://rendeles.mandragorabudapest.hu/static/media/${item.img}`)} />
    </div>
  );
};

interface ItemProps {
  item: IItem<{}>;
  dpAddToCart: typeof addToCart;
  isMenu: boolean;
  index: any;
  setStep: (a: string) => void;
}

const mapDispatchToProps = {
  dpAddToCart: addToCart,
};

export default connect(null, mapDispatchToProps)(Item);
