import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import Img from './assets/main.jpg';
import ItemContainer from './components/ItemContainer/ItemContainer';
import Modal from './components/Modal/Modal';
import IItem from './types/IItem';
import MyForm from './types/MyForm';
import Dates from './types/Dates';
import Email from './types/Email';
import { ReactComponent as MandragoraSvg } from './assets/mandragora.svg';
import { ReactComponent as Cart1Svg } from './assets/cart1.svg';
import { ReactComponent as Cart2Svg } from './assets/cart2.svg';
import { ReactComponent as Cart3Svg } from './assets/cart3.svg';
import { ReactComponent as Cart4Svg } from './assets/cart4.svg';
import { ReactComponent as Cart5Svg } from './assets/cart5.svg';
import { ReactComponent as Cart6Svg } from './assets/cart6.svg';
import { ReactComponent as Cart7Svg } from './assets/cart7.svg';
import { ReactComponent as Cart8Svg } from './assets/cart8.svg';
import { ReactComponent as Cart9Svg } from './assets/cart9.svg';
import { ReactComponent as CartMoreSvg } from './assets/cartMore.svg';
import { ReactComponent as InfoSvg } from './assets/info.svg';
import { ReactComponent as MenuSvg } from './assets/menu.svg';
import { ReactComponent as PlusSvg } from './assets/plus.svg';
import { ReactComponent as MinusSvg } from './assets/minus.svg';
import { ReactComponent as RemoveCartSvg } from './assets/removeCart.svg';
import { ReactComponent as SpinnerSvg } from './assets/spinner.svg';
import { addToCart, removeFromCart, subtractFromCart, emptyCart } from './actions/Action';
import { setData } from './actions/DataAction';
import { Link } from 'react-router-dom';
import './App.scss';

const App = ({ main, dpAddToCart, dpSubtractFromCart, dpRemoveFromCart, dpSetData, mainData, hasLoaded, streets, menuStreets, dpEmptyCart, postal, menuPostal }: AppProps) => {
  const isMenu = window.location.pathname === '/menu';
  const data = isMenu ? mainData.menu : mainData.data;
  const totalPrice = main.cart.reduce((prev, next) => prev + next.price * next.generic.amount, 0);
  const imgRef = useRef(null);
  const [isTransparent, setIsTransparent] = useState(true);
  const [step, setStep] = useState('cart');
  const [loading, setLoading] = useState(false);
  const [possibleNumber, setPosNum] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [select, setSelect] = useState(null);
  const [isMenuActive, setIsMenuActive] = useState(false);
  const [form, setForm] = useState(new MyForm());
  const selectedStreets = streets;
  const selectedPostals = postal;
  const streetFilter = (street: any, myForm: MyForm) => street.value.toLowerCase().includes(myForm.address.toLowerCase()) && street.postal.toLowerCase().includes(myForm.postNumber.toLowerCase());
  const postalFilter = (post: any, myForm: MyForm) => post.value.toLowerCase().includes(myForm.postNumber.toLowerCase()) && (selectedStreets.filter((street) => street.value.toLowerCase().includes(myForm.address.toLowerCase())).some((street) => street.postal === post.value));
  const dateData = Dates.getDays().filter((day, i) => !(Dates.current.getHours() >= 16 && i === 0) && Dates.current.getDay() !== 0 && Dates.current.getDay() !== 6);

  const disabled = (
    form.firstName.length === 0
    || form.lastName.length === 0
    || !new RegExp(/[a-zA-Z0-9._%+-]+@[a-zA-Z0-9._%+-]+\.[a-zA-Z]/).test(form.email)
    || !new RegExp(/^[+]{0,1}[0-9-/()\ ]{9,20}$/g).test(form.phone)
    || form.paymentMethod.length === 0
    || form.deliveryDate.length === 0
    || (form.deliveryDate !== 'Most' && form.deliveryTime.length === 0)
    || (
      form.deliveryType === 'Kiszállítás' && (
        (
          form.postNumber.length === 0 && !(
            selectedPostals.some((post) => post.value.toLowerCase().includes(form.postNumber.toLowerCase()))
          )
        ) || (
          form.address.length === 0 && !(
            selectedStreets.some((street) => street.value.toLowerCase().includes(form.address.toLowerCase()))
          )
        ) || (
          form.houseNumber.length === 0 && !(
            possibleNumber.length > 0 && possibleNumber.some((number) => number.toLowerCase().includes(form.houseNumber.toLowerCase()))
          )
        )
      )
    )
  );

  const disabled2 = form.deliveryType === 'Kiszállítás' && form.price === 300 && totalPrice < 2800

  const headerRefs: {
    current:
    {
      current: {
        scrollIntoView: (options: ScrollIntoViewOptions) => {},
      },
    }[],
  } = useRef([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20].map(() => React.createRef()));

  const menuPurchased = main.cart.reduce(
    (prev, next) => (next.generic.menu !== undefined) ? ({ ...prev, [next.generic.menu]: prev[next.generic.menu] + next.generic.amount }) : prev,
    { 0: 0, 1: 0, 2: 0 },
  );
  const threeCourse = Math.min(menuPurchased[0], menuPurchased[1], menuPurchased[2]);
  const twoCourse = [menuPurchased[0] - threeCourse, menuPurchased[1] - threeCourse, menuPurchased[2] - threeCourse].sort((a, b) => a - b)[1];

  useEffect(() => {
    if (!hasLoaded) {
      fetch('./static/media/data.json', { method: 'GET' })
        .then((res) => res.json())
        .then((res) => dpSetData(res));
      if (localStorage.getItem('form') !== null) {
        const local = JSON.parse(localStorage.getItem('form'));
        setForm({ ...local, paymentMethod: '', deliveryTime: '', deliveryDate: 'Most', deliveryType: 'Átvétel', comment: '' });
      }
      if (!localStorage.getItem('firstTime')) {
        setStep('info');
        setIsOpen(true);
        localStorage.setItem('firstTime', 'true');
      }
    }
  }, [])

  useEffect(() => {
    const headerRefOptions: IntersectionObserverInit = { rootMargin: '-85% 0px 20px 0px' };
    const headerObserver = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (!entry.isIntersecting) {
            setIsTransparent(false);
          } else {
            setIsTransparent(true);
          }
        });
      },
      headerRefOptions,
    );
    if (imgRef.current) {
      headerObserver.observe(imgRef.current);
    }

    return () => headerObserver.disconnect();
  }, [data]);

  const setFormData = (key: string, value: string, ...a: any[]) => {
    if (a) {
      setForm((prevForm) => ({ ...prevForm, [key]: value, [a[0]]: a[1] }));
      if (a[2]) {
        setForm((prevForm) => ({ ...prevForm, [key]: value, [a[2]]: a[3] }));
      }
      return;
    }
    setForm((prevForm) => ({ ...prevForm, [key]: value }));
  }

  const getCart = (item: IItem<{ amount: number }>[]) => {
    const current = item.reduce((prev, next) => prev + next.generic.amount, 0)
    switch (current) {
      case 0:
        return;
      case 1:
        return (<Cart1Svg />);
      case 2:
        return (<Cart2Svg />);
      case 3:
        return (<Cart3Svg />);
      case 4:
        return (<Cart4Svg />);
      case 5:
        return (<Cart5Svg />);
      case 6:
        return (<Cart6Svg />);
      case 7:
        return (<Cart7Svg />);
      case 8:
        return (<Cart8Svg />);
      case 9:
        return (<Cart9Svg />);
      default:
        return (<CartMoreSvg />);
    }
  }

  const getByStep = (step: string) => {
    switch (step) {
      case 'cart': {
        return (
          <div className="orders">
            <div className="orders-title">Kosár tartalma:</div>
            {main.cart.map((item) => (
              <div className="ordered-item">
                <div className="ordered-item-content">
                  <div className="ordered-item-header">
                    <div>{item.name}</div>
                    <div style={{ display: 'flex', marginLeft: 'auto' }}>
                      <div className="item-original-price">{`${item.price} Ft x `}</div>
                      <div className="action-row-buttons">
                        <div
                          className="calc-container"
                          role="button"
                          tabIndex={-1}
                          onClick={() => item.generic.amount > 1 && dpSubtractFromCart({ ...item, generic: { amount: 1 } })}
                        >
                          <MinusSvg className="calc" />
                        </div>
                        <div className="counter">{item.generic.amount}</div>
                        <div
                          className="calc-container"
                          role="button"
                          tabIndex={-1}
                          onClick={() => dpAddToCart({ ...item, generic: { amount: 1 } })}
                        >
                          <PlusSvg className="calc" />
                        </div>
                      </div>
                      <div className="item-full-price">{` = ${item.price * item.generic.amount} Ft`}</div>
                      <RemoveCartSvg className="remove-cart" onClick={() => dpRemoveFromCart(item)} />
                    </div>
                  </div>
                  <div className="comment"><label><textarea onBlur={(e) => dpAddToCart({ ...item, generic: { ...item.generic, desc: e.target.value, amount: 0 } })} rows={2} placeholder="Megjegyzés" id="" /></label></div>
                </div>
                <img src={`http://rendeles.mandragorabudapest.hu/static/media/${item.img}`} alt="" />
              </div>
            ))}
            {(threeCourse !== 0 || twoCourse !== 0) && (
              <div className="ordered-item error">{`${threeCourse * 950 + twoCourse * 500} Ft kedvezmény a rendelésből`}</div>
            )}
            <div className="full-price">
              <div>{`Összesen: ${main.cart.reduce((prev, next) => prev + next.price * next.generic.amount, -(threeCourse * 950 + twoCourse * 500))} Ft`}</div>
              <div className='next-button' onClick={() => setStep('details')}>Tovább</div>
            </div>
          </div>
        );
      }

      case 'details':
        return (
          <div className="delivery-details">
            <form onSubmit={(e) => e.preventDefault()}>
              <div>
                <label>
                  Keresztnév*
                  <input onChange={(e) => setFormData(e.target.name, e.target.value)} value={form.firstName} type="text" name="firstName" id="firstName" />
                </label>
                <label>
                  Vezetéknév*
                  <input onChange={(e) => setFormData(e.target.name, e.target.value)} value={form.lastName} type="text" name="lastName" id="lastName" />
                </label>
              </div>
              <div>
                <label>
                  Email*
                  <input onChange={(e) => setFormData(e.target.name, e.target.value)} value={form.email} type="text" name="email" id="email" />
                </label>
                <label>
                  Telefonszám*
                  <input onChange={(e) => setFormData(e.target.name, e.target.value)} value={form.phone} type="text" name="phone" id="phone" />
                </label>
              </div>
              <div>
                <label>
                  Átvétel módja*
                  <select onClick={() => setSelect({ title: 'Átvétel módja*', data: [{ title: '', value: '' }, { value: 'Átvétel', title: 'Személyes átvétel - 0 Ft'}, { title: `Kiszállítás - ${totalPrice < 5000 ? form.price : 0} Ft`, value: 'Kiszállítás'}], type: 'deliveryType' })} onChange={(e) => setFormData(e.target.name, e.target.value)} value={form.deliveryType} name="deliveryType" id="deliveryType">
                    {/* <option value=""></option> */}
                    <option value="Átvétel">Személyes átvétel - 0 Ft</option>
                    {/* <option value="Kiszállítás">{`Kiszállítás - ${totalPrice < 5000 ? `${form.price}` : 0} Ft`}</option> */}
                  </select>
                </label>
                {form.deliveryType === 'Kiszállítás' && (<InfoSvg className="infocircle small" onClick={() => setStep('info2')} />)}
                <label>
                  Fizetés módja*
                  <select onClick={() => setSelect({ data: [{ value: '' }, { value: 'Készpénz' }, { value: 'Kártya' }, { value: 'Szépkártya'}], type: 'paymentMethod', title: 'Fizetés módja*' })} onChange={(e) => setFormData(e.target.name, e.target.value)} value={form.paymentMethod} name="paymentMethod" id="paymentMethod">
                    <option value=""></option>
                    <option value="Készpénz">Készpénz</option>
                    <option value="Kártya">Kártya</option>
                    <option value="Szépkártya">Szépkártya</option>
                  </select>
                </label>
              </div>
              <div>
                <label>
                  {`${form.deliveryType || 'Kiszállítás'} ideje*`}
                  <select onClick={() => setSelect({ onClick: (item: any) => { setFormData('deliveryDate', item.value, 'deliveryTime', '') }, data: [{ value: 'Most' }, ...dateData.map((day) => ({ value: day })).splice(0, 2)], type: 'deliveryDate', title: `${form.deliveryType || 'Kiszállítás'} ideje*` })} onChange={(e) => setForm({ ...form, [e.target.name]: e.target.value, deliveryTime: '' })} value={form.deliveryDate} name="deliveryDate" id="deliveryDate">
                    { (Dates.current.getDay() === 6 || Dates.current.getDay() === 0) 
                      ? (<option disabled value="">Szombaton és Vasárnap zárva vagyunk</option>)
                      : (<option value="Most">Most</option>)
                    }
                    {
                      dateData.map((day, i) => i < 2
                        ? (
                          <option value={day}>{day}</option>
                        )
                        : null
                      )
                    }
                  </select>
                </label>
                <label>
                  {form.deliveryDate !== 'Most'
                    // && (hasMenu ? form.deliveryType === 'Átvétel' : true)
                    && (
                    <>
                      Időpont*
                      <select onClick={() => setSelect({ title: 'Időpont*', data: [{ value: '' }, ...Dates.getIntervals(form.deliveryDate).map((int) => ({ value: int }))], type: 'deliveryTime' })} onChange={(e) => setFormData(e.target.name, e.target.value)} value={form.deliveryTime} name="deliveryTime" id="deliveryTime">
                        <option value={''}></option>
                        {
                          Dates.getIntervals(form.deliveryDate).map((interval) => (
                            <option value={interval}>{interval}</option>
                          ))
                        }
                      </select>
                    </>
                  )}
                  <p>A minimum kiszállítási érték 2800 Ft (A külső zónában). A kiszállítás ára a zónától függően 0 Ft / 300 Ft. 5000 Ft felett ingyenes.</p>
                </label>
              </div>
              {form.deliveryType === 'Kiszállítás' && (
                <>
                  <div>
                    <label className={selectedPostals.some(({ value }) => value.includes(form.postNumber)) ? '' : 'error'}>
                      Irányítószám*
                      <input  onClick={() => setSelect({ filter: postalFilter, type: 'postNumber', data: selectedPostals, autocomplete: true, title: 'Irányítószám*' })} autoComplete="new-password" onChange={(e) => setFormData(e.target.name, e.target.value)} value={form.postNumber} className="autocomplete" type="text" name="postNumber" id="postNumber" />
                      <div className="auto-complete-container">
                        {selectedPostals.filter((post) => postalFilter(post, form)).map((post) => (
                          <div
                            className="auto-complete-item"
                            onMouseDown={(e) => { e.stopPropagation(); setFormData('postNumber', post.value); }}
                            onClick={(e) => { e.stopPropagation(); e.preventDefault(); }}
                          >{post.value}</div>
                        ))}
                      </div>
                    </label>
                    <label className={selectedStreets.some((street) => street.value.toLowerCase().includes(form.address.toLowerCase())) ? '' : 'error'}>
                      Utca*
                      <input
                        onClick={() => setSelect({
                          onClick: (item: any) => { 
                            if (form.postNumber === '') setFormData('postNumber', selectedStreets.reduce((prev, next) => next.value === item.value ? prev + 1 : prev, 0) === 1 ? item.postal : '', 'address', item.value, 'price', item.price);
                            else setFormData('address', item.value, 'price', item.price); setPosNum(item.houseNumbers || []);
                          },
                          filter: streetFilter,
                          type: 'address',
                          data: selectedStreets,
                          autocomplete: true,
                          title: 'Utca',
                        })}
                        autoComplete="new-password"
                        onChange={(e) => {
                          setFormData(e.target.name, e.target.value, 'price', selectedStreets.reduce((prev, next) => next.value === e.target.value && next.postal === form.postNumber ? next.price : prev, 300));
                        }}
                        value={form.address}
                        className="autocomplete"
                        type="text"
                        name="address"
                        id="address"
                      />
                      <div className="auto-complete-container">
                        {selectedStreets.filter((street) => streetFilter(street, form)).map((street) => (
                          <div
                            className="auto-complete-item"
                            onMouseDown={(e) => { e.stopPropagation(); if(form.postNumber === '') setFormData('postNumber', selectedStreets.reduce((prev, next) => next.value === street.value ? prev + 1 : prev, 0) === 1 ? street.postal : '', 'address', street.value, 'price', street.price); else setFormData('address', street.value, 'price', street.price); setPosNum(street.houseNumbers || []) }}
                            onClick={(e) => { e.stopPropagation(); e.preventDefault(); }}
                          >{street.value}</div>
                        ))}
                      </div>
                    </label>
                  </div>
                  <div>
                    <label className={possibleNumber.length > 0 && !possibleNumber.some((number) => number.toLowerCase().includes(form.houseNumber.toLowerCase())) ? 'error' : ''}>
                      Házszám*
                      <input onClick={() => setSelect(possibleNumber.length > 0 ? { type: 'houseNumber', data: possibleNumber.map((number) => ({ value: number })), title: 'Házszám*' } : null)} autoComplete="new-password" onChange={(e) => setFormData(e.target.name, e.target.value)} value={form.houseNumber} className="autocomplete" type="text" name="houseNumber" id="houseNumber" />
                      {possibleNumber.length > 0 && (<div className="auto-complete-container">
                        {possibleNumber.map((number) => (
                          <div
                            className="auto-complete-item"
                            onMouseDown={(e) => { e.stopPropagation(); setFormData('houseNumber', number) }}
                            onClick={(e) => { e.stopPropagation(); e.preventDefault(); }}
                          >{number}</div>
                        ))}
                      </div>)}
                    </label>
                    <label className="small">
                      Em.
                      <input onChange={(e) => setFormData(e.target.name, e.target.value)} value={form.floor} className="autocomplete" type="text" name="floor" id="floor" />
                    </label>
                    <label className="small">
                      Ajtó
                      <input onChange={(e) => setFormData(e.target.name, e.target.value)} value={form.door} className="autocomplete" type="text" name="door" id="door" />
                    </label>
                    <label className="small">
                      Csengő
                      <input onChange={(e) => setFormData(e.target.name, e.target.value)} value={form.bell} className="autocomplete" type="text" name="bell" id="bell" />
                    </label>
                  </div>
                </>
              )}
              <div>
                <label className="big">
                  Megjegyzés a kiszállításhoz
                  <textarea onChange={(e) => setFormData(e.target.name, e.target.value)} value={form.comment} name="comment" id="comment" />
                </label>
              </div>
              <div className="full-price">
                <div className="next-button" onClick={() => setStep('cart')}>Vissza</div>
                <div style={{ font: '400 13px sans-serif', minHeight: 13 }}>{form.deliveryDate === 'Most' ? `Várhatóan ${form.deliveryType === 'Kiszállítás' ? '25-45 perc a kiszállítás' : '15-20 prec múlva átvehető'}` : ''}</div>
                <div>{`Összesen${form.deliveryType === 'Kiszállítás' ? ' a kiszállítással' : ''}: ${totalPrice - (threeCourse * 950 + twoCourse * 500) + (form.deliveryType === 'Kiszállítás' && totalPrice < 500 ? +`${form.price}` : 0)} Ft`}</div>
                <div
                  className={`next-button${disabled ? ' disabled' : ''}${disabled2 ? ' disabled price' : ''}`}
                  onClick={() => {
                    if (!disabled) {
                      localStorage.setItem('form', JSON.stringify(form));
                      setLoading(true);

                      fetch('http://rendeles.mandragorabudapest.hu/api/index.php/token/new').then((res) => res.json()).then(res => {
                        const header = {
                          method: 'POST',
                          'Content-Type': 'application/json',
                        };
                        const headers = {
                          'X-Api-Key': 'Bearer ' + res.data.accessToken,
                        };
                        const body1 = JSON.stringify({
                          subject: 'Rendelés visszaigazolás - Mandragóra',
                          message: Email.getContactEmail(form),
                          to: form.email,
                          from: 'rendeles@mandragorabudapest.hu'
                        });
                        const body2 = JSON.stringify({
                          subject: `Új rendelés - ${form.firstName} ${form.lastName}`,
                          message: Email.getOrderEmail(form, main.cart, threeCourse * 600 + twoCourse * 200, form.deliveryType === 'Kiszállítás' && totalPrice < 5000 ? +`${form.price}` : 0),
                          to: 'rendeles@mandragorabudapest.hu',
                          from: form.email
                        });
                        fetch('http://rendeles.mandragorabudapest.hu/api/index.php/mail/send', { ...header, headers, body: body1 })
                          .then((res) => res.json())
                          .then(() => {
                            fetch('http://rendeles.mandragorabudapest.hu/api/index.php/mail/send', { ...header, headers, body: body2 })
                              .then((res) => res.json())
                              .then(() => {
                                setLoading(false);
                                setStep('success');
                                dpEmptyCart();
                              })
                          });
                      }).catch((err) => console.error(err))
                    }
                  }}
                >
                  Megrendelem{loading && <SpinnerSvg className="spinner" />}
                </div>
              </div>
            </form>
          </div>
        );

      case 'success':
        return (
          <div style={{ fontSize: 24 }}>A megrendelést sikeresen megkaptuk, amint feldolgozzuk visszajelzünk emailben.</div>
        );

      case 'info2':
        return (
          <div className="info2">
            Kiszállítási zónák
            <div>
              <div>
                <div>0 Ft</div>
                <img src="http://rendeles.mandragorabudapest.hu/static/media/menu.jpg" alt="" />
              </div>
              <div>
                <div>300 Ft</div>
                <img src="http://rendeles.mandragorabudapest.hu/static/media/alacarte.jpg" alt="" />
              </div>
            </div>
            <div className="next-button" onClick={() => setStep('details')}>Vissza</div>
          </div>
        );

      case 'info':
        return (
          <div className="info-modal">
            <div className="main-header">Rendelési feltételek</div>
            <div className="header">Rendelés leadásának módja</div>
            <div>Webshopunkon: rendeles.mandragorabudapest.hu </div>
            <div>Telefonon: +36205328993, +3612022165 10:00-16:30 között</div>
            <div>Emailben: rendeles@mandragorabudapest.hu </div>
            <div>Ételeinket kérheti személyes átvétellel vagy kiszállítva is.</div>
            <div className="header">Személyes átvétel</div>
            <div>Ételeinket a teljes nyitvatartás alatt megrendelheti, amelyeket frissen készítünk el, a
rendeléstől számítva 15-20 percen belül átvehető. Kollégáink minden esetben
visszajeleznek arról, hogy mikorra készülünk el a rendeléssel. Hétfőtől-Szombatig
11:30-21:00 között vehetőek át a megrendelések.</div>
            <div>A hét összes napjára elő lehet rendelni ételeinket ebben az esetben a kért időpontra  készítjük el frissen</div>
            <div>Telefonon, illetve emailen történő megrendelés esetén a következő adatokat  szükséges megadni:</div>
            <div className="inner">Név</div>
            <div className="inner">Telefonszám </div>
            <div className="inner">Rendelt ételek listája </div>
            <div className="inner">Átvétel ideje</div>
            <div>Áraink az ÁFA-t tartalmazzák. ÁFA-s számla igényét kérjük, a rendelési folyamat során  kollégánknak jelezze, azt utólag nincs módunkban kiállítani. </div>
          </div>
        );

      default:
        return (
          <img width="100%" src={step} alt="" />
        );
    }
  }

  const selectMod = (select: { onClick?: (item: any) => void, filter?: (item: any, myForm: MyForm) => boolean, autocomplete?: boolean, data: { value: string, title?: string }[], type: 'deliveryType' | 'deliveryTime', title: string }) => {
    if (!select) return null;
    const current = select.autocomplete ? select.data.filter((item) => select.filter(item, form)) : select.data;
    return (
      <div>
        <div className="select-title">{select.title}</div>
        { select.autocomplete && (
          <label>
            <input autoFocus value={form[select.type]} onChange={(e) => setFormData(select.type, e.target.value)} type="text" name={select.type} id=""/>
          </label>
        )}
        <div className={`select-box${select.autocomplete ? ' autocomplete' : ''}`}>
          { current.map((item) => (
            <div onClick={() => { setSelect(null); if (select.onClick) select.onClick(item); else setFormData(select.type, item.value) }} className={`select-item${item.value === form[select.type] ? ' selected' : ''}`}>{item.title || item.value}</div>
          )) }
        </div>
      </div>
    );
  }

  if (data.length === 0) return null;

  return (
    <div className="app">
      <Modal isOpen={isOpen} onClose={() => { setIsOpen(false); setStep('cart'); }}>{getByStep(step)}</Modal>
      <Modal customClass="mobile-select" isOpen={select !== null} onClose={() => setSelect(null)}>{selectMod(select)}</Modal>
      <nav className={isTransparent ? '' : 'navigation'}>
        <div className="navigation-types">
          {data && Object.keys(data).map((value, i) => (
            <div>
              <div
                role="button"
                tabIndex={-1}
                onClick={() => headerRefs.current[i].current.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' })}
              >{value}</div>
            </div>
          ))}
        </div>
        <div className="navigation-types-mobile">
          <div className="nav-icon" onClick={() => setIsMenuActive(!isMenuActive)}>
            <MenuSvg />
          </div>
          <div className={`nav-data${isMenuActive ? ' active' : ''}`}>
            <div className="nav-icon" onClick={() => setIsMenuActive(!isMenuActive)}>
              <MenuSvg />
            </div>
            {data && Object.keys(data).map((value, i) => (
              <div>
                <div
                  role="button"
                  tabIndex={-1}
                  onClick={() => {
                    headerRefs.current[i].current.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
                    setIsMenuActive(false);
                  }}
                >{value}</div>
              </div>
            ))}
          </div>
        </div>
        {
          isMenu
            ? <Link className="link" to="/">Itallap</Link>
            : <Link className="link" to="/menu">Étlap</Link>
        }
        <div
          className="cart"
          role="button"
          tabIndex={-1}
          onClick={() => { setStep('cart'); setIsOpen(true); }}
        >
          {getCart(main.cart)}
        </div>
        <InfoSvg className="infocircle" onClick={() => { setStep('info'); setIsOpen(true); }} />
        <div className="background" />
      </nav>
      <div className="main-img-container">
        <div className="svg-container">
          <MandragoraSvg className="logo" />
        </div>
        <img ref={imgRef} className="main-img" src={Img} alt="" />
      </div>
      <div style={{ fontSize: 20, textAlign: 'center', padding: '8px 0' }}>2 fogás rendelésénél 500Ft, 3 fogás rendelésénél 950Ft kedvezményt adunk.</div>
      <div className="scroller">
        {
          data && Object.entries(data).map(([key, items]: [string, IItem<{}>[]], i: number) => (
            <ItemContainer
              addRef={headerRefs.current[i]}
              items={items}
              heading={key}
              setStep={(src) => { setStep(src); setIsOpen(true); }}
              isMenu={isMenu}
              index={i}
            />
          ))
        }
      </div>
    </div>
  );
}

const mapStateToProps = (store: any) => ({
  main: store.main,
  mainData: store.data,
  hasLoaded: store.data.hasLoaded,
  streets: store.data.streets,
  menuStreets: store.data.menuStreets,
  postal: store.data.postal,
  menuPostal: store.data.menuPostal,
});

const mapDispatchToProps = {
  dpAddToCart: addToCart,
  dpSubtractFromCart: subtractFromCart,
  dpRemoveFromCart: removeFromCart,
  dpSetData: setData,
  dpEmptyCart: emptyCart,
};

export type Index = 0 | 1 | 2;

interface AppProps {
  main: { cart: IItem<{ amount: number, menu?: Index, has?: Index[], optional: Index[] }>[] };
  dpAddToCart: typeof addToCart,
  dpSubtractFromCart: typeof subtractFromCart,
  dpRemoveFromCart: typeof removeFromCart,
  dpSetData: typeof setData,
  dpEmptyCart: typeof emptyCart;
  mainData: any,
  hasLoaded: boolean,
  streets: { value: string, postal: string, estimate: number, houseNumbers?: number[], price: number }[],
  menuStreets: { value: string, postal: string, estimate: number, houseNumbers?: number[], price: number }[],
  postal: any[],
  menuPostal: any[],
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
