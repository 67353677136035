import { EMPTY_CART, MainActionTypes, REMOVE_FROM_CART, SUBTRACT_FROM_CART } from '../../actions/Action';
import { ADD_TO_CART } from '../../actions/Action';
import IItem from '../../types/IItem';

const mainReducer = (state: { cart: IItem<{ amount: number, menu?: number }>[] } = { cart: [] }, action: MainActionTypes) => {
  switch(action.type) {
    case ADD_TO_CART: {
      return {
        ...state,
        cart: state.cart.find((item) => item.name === action.payload.item.name)
          ? [
            ...state.cart.map((item) => item.name === action.payload.item.name
              ? { ...item, generic: { amount: item.generic.amount + action.payload.item.generic.amount, menu: item.generic.menu, desc: action.payload.item.generic.desc } }
              : item
            )
          ]
          : [
            ...state.cart,
            action.payload.item,
          ],
      };
    }

    case SUBTRACT_FROM_CART: {
      return {
        ...state,
        cart: state.cart.find((item) => item.name === action.payload.item.name)
          ? [
            ...state.cart.map((item) => item.name === action.payload.item.name
              ? { ...item, generic: { amount: item.generic.amount - action.payload.item.generic.amount, menu: item.generic.menu } }
              : item
            )
          ]
          : [
            ...state.cart,
            action.payload.item,
          ],
      };
    }

    case REMOVE_FROM_CART: {
      return {
        ...state,
        cart: [
          ...state.cart.filter((item) => item.name !== action.payload.item.name),
        ],
      };
    }

    case EMPTY_CART: {
      return {
        ...state,
        cart: [],
      };
    }

    default:
      return state;
  }
}

export default mainReducer;
