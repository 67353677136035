import React from 'react';
import IItem from 'src/types/IItem';
import Item from '../Item/Item';
import './item-container.scss';

const ItemContainer = ({ items, heading, addRef, isMenu, index, setStep }: ItemContainerProps): JSX.Element => (
  <div className="item-container" ref={addRef}>
    <div className="item-container-title">{heading}</div>
    {
      items && items.map((item) => (<Item item={item} isMenu={isMenu} index={index} setStep={setStep} />))
    }
  </div>
);

interface ItemContainerProps {
  items: IItem<{}>[];
  heading: string;
  addRef: any;
  isMenu?: boolean;
  index?: number;
  setStep: (a: string) => void;
}

export default ItemContainer;
