import React from 'react';

const Modal = ({ children, isOpen, onClose, customClass }: ModalProps) => {

  return (
    <div className={`modal${isOpen ? ' active' : ''}${customClass ? ` ${customClass}` : ''}`} onClick={onClose}>
      <div className="modal-container" onClick={(e) => e.stopPropagation()}>
        <div className="close" onClick={onClose}>X</div>
        {children}
      </div>
    </div>
  );
}

interface ModalProps {
  children: JSX.Element;
  isOpen: boolean;
  onClose: () => void;
  customClass?: string;
}

export default Modal;
